import { Filter } from '~/domain/core/Filter';

export class OpportunityGeneralFilter extends Filter{
    constructor(obj){
        super({
            params: obj, 
            keys: [
                "ciclos",
                "clasificaciones",
                "fecha_cierre",
                "fecha_identifica",
                "forecast",
                "ganadas",
                "id_filtro",
                "id_w_filtro",
                "modulo",
                "origen",
                "perdidas",
                "probabilidad",
                "productos",
                "responsables",
                "tipo",
                "valor",
                "vigentes"
            ]
        });
    }
}