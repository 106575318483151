import { OpportunityGeneralFilter } from "../OpportunityGeneralFilter";

export class OpportunityGeneralFilterFactory {
    static instantiate(obj){
        return new OpportunityGeneralFilter(obj)
    }

    static instantiateEmpty(){
        return new OpportunityGeneralFilter({
            ciclos: [],
            clasificaciones: [],
            fecha_cierre: [],
            fecha_identifica: [],
            forecast: [0, 100],
            ganadas: false,
            id_filtro: null,
            id_w_filtro: null,
            modulo: 8,
            origen: [],
            perdidas: false,
            probabilidad: [0, 100],
            productos: [],
            responsables: [],
            tipo: [],
            valor: [],
            vigentes: false
        })
    }
}