<template>
    <modal ref="modalFiltrarOp" tamano="modal-xl" titulo="Filtro">
        <div class="container-fluid filtro-op">
            <titulo-divisor titulo="Datos generales" />
            <!-- fecha cierre -->
            <div class="row my-2">
                <div class="col-auto">
                    <div class="bg-dg border br-5 f-12 wh-box d-middle pl-2">
                        <p>Fecha de cierre</p>
                    </div>
                </div>
                <div class="col-5">
                    <el-date-picker 
                    v-model="rangoFechaCierre" 
                    type="daterange" 
                    range-separator="a" 
                    start-placeholder="Día inicio"
                    end-placeholder="Día Fin"
                    size="small"
                    />
                </div>
            </div>
            <!-- ranges: Probabilidad y valor -->
            <div class="row my-2">
                <div class="col-10">
                    <div class="row">
                        <div class="col-auto">
                            <div class="bg-dg border br-5 f-12 wh-box d-middle pl-2">
                                <p>Probabilidad</p>
                            </div>
                        </div>
                        <div class="col">
                            <el-slider v-model="rangoProbabilidad" range :max="100" :format-tooltip="formatTooltipRange" />
                        </div>
                    </div>
                </div>
                <!-- <div class="col-6">
                    <div class="row">
                        <div class="col-auto">
                            <div class="bg-dg border br-5 f-12 wh-box d-middle pl-2">
                                <p>Valor</p>
                            </div>
                        </div>
                        <div class="col">
                            <el-slider v-model="rangoValor" range :min="dataFiltros.valor_minimo" :max="dataFiltros.valor_maximo+1" :format-tooltip="formatTooltipValor" />
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- ciclo -->
            <div class="row my-2">
                <div class="col-auto">
                    <div class="bg-dg border br-5 f-12 wh-box d-middle pl-2">
                        <p>Ciclo</p>
                    </div>
                </div>
                <div class="col-9">
                    <el-select v-model="filtro.ciclos" multiple filterable placeholder="Seleccionar un ciclo" size="small" class="w-100">
                        <el-option v-for="item in dataFiltros.ciclos" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <!-- Tipo -->
            <div class="row my-2">
                <div class="col-auto">
                    <div class="bg-dg border br-5 f-12 wh-box d-middle pl-2">
                        <p>Tipo</p>
                    </div>
                </div>
                <div class="col-9">
                    <el-select v-model="filtro.tipos" multiple filterable placeholder="Seleccionar un tipo" size="small" class="w-100">
                        <el-option v-for="item in dataFiltros.tipos" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <!-- Responsables -->
            <div class="row my-2">
                <div class="col-auto">
                    <div class="bg-dg border br-5 f-12 wh-box d-middle pl-2">
                        <p>Responsables</p>
                    </div>
                </div>
                <div class="col-9">
                    <el-select v-model="filtro.responsables" multiple filterable placeholder="Seleccionar un resposanble" size="small" class="w-100">
                        <el-option v-for="item in dataFiltros.responsables" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
            </div>

            <!-- filtros criterios -->
            <div class="row mb-3 titulo-divisor mt-5">
                <div class="col-auto my-auto">
                    <el-select v-model="idCriteriosSelect" multiple filterable collapse-tags placeholder="Añadir criterio" size="small" class="w-100">
                        <el-option v-for="item in dataFiltros.lista_valores" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col my-auto pl-0">
                    <hr class="my-0 bg-white" />
                </div>
            </div>
            <section class="lista-filtros-criterios">
                <!-- creterio -->
                <div v-for="criterio in dataCriteriosSelect" :key="criterio.id_cliente_dato_seleccionado" class="row my-2">
                    <div class="col-auto">
                        <div class="bg-criterio border br-5 f-12 wh-box d-middle pl-2">
                            <p>{{ criterio.nombre }}</p>
                        </div>
                    </div>
                    <div class="col-9" v-if="criterio.submodulo == 'pais'">
                        <el-select v-model="criterio.arrayFiltro" 
                        multiple filterable placeholder="Seleccionar"
                        size="small" class="w-100">
                            <el-option v-for="item in select_countries" :key="item.id" :label="item.pais" :value="item.id" />
                        </el-select>
                    </div>
                    <div class="col-9" v-else-if="criterio.submodulo == 'estado'">
                        <el-select v-model="criterio.arrayFiltro" 
                        multiple filterable placeholder="Seleccionar"
                        size="small" class="w-100" remote
                        :remote-method="getDataCriterioEstado"
                        :loading="loadingSelct">
                            <el-option v-for="item in select_states_paginated" :key="item.id" :label="`${item.estado}, ${item.pais}`" :value="item.id" />
                        </el-select>
                    </div>
                    <div class="col-9" v-else-if="criterio.submodulo == 'ciudad'">
                        <el-select v-model="criterio.arrayFiltro" 
                        multiple filterable placeholder="Seleccionar"
                        size="small" class="w-100" remote
                        :remote-method="getDataCriterioCiudad"
                        :loading="loadingSelct">
                            <el-option v-for="item in select_city_paginated" :key="item.id" :label="`${item.ciudad}, ${item.estado}, ${item.pais}`" :value="item.id" />
                        </el-select>
                    </div>
                    <div class="col-9" v-else>
                        <el-select v-model="criterio.arrayFiltro" multiple placeholder="Seleccionar" size="small" class="w-100">
                            <el-option v-for="item in criterio.data" :key="item.id" :label="item.nombre" :value="item.id_padre" />
                        </el-select>
                    </div>
                    <div class="col-auto my-auto">
                        <div class="wh-18 d-middle-center br-3 text-white f-14">
                            <i class="icon-window-close cr-pointer" @click="quitarCriterio(criterio.id_criterio)" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <template #end-buttons>
            <button type="button" class="btn ml-1 mr-2 f-12 bg-5d text-white f-300 w-80px" @click="cleanFilter">
                Limpiar
            </button>
            <button type="button" class="btn ml-1 mr-2 f-12 btn-general f-300 w-80px" @click="filtrarOp">
                Filtrar
            </button>
        </template>
    </modal>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import { OpportunityGeneralFilterFactory } from '~/domain/filters/opportunity/factories/OpportunityGeneralFilterFactory'
export default {
    data(){
        return {
            idCriteriosSelect: '',
            dataCriteriosSelect: [],
            filtroGeneral: OpportunityGeneralFilterFactory.instantiateEmpty(),
            loadingSelct: false
        }
    },
    computed: {
        ...mapGetters({
            filtros: 'oportunidades/oportunidades/filtros',
            dataFiltros: 'oportunidades/oportunidades/dataFiltros',
            criterioData: 'oportunidades/oportunidades/criterio',
            select_countries: 'selects/selects/select_countries',
            numCriteriosFiltro: 'oportunidades/oportunidades/numCriteriosFiltro',
            select_city_paginated: 'selects/selects/select_city_paginated',
            select_states_paginated: 'selects/selects/select_states_paginated'
        }),
        filtro:{
            get(){ return this.filtros },
            set(val){ this.set_filtros(val)}
        },
        rangoFechaCierre:{
            get(){ 
                return this.filtro.fecha_cierre_inicio
                    ? [this.filtro.fecha_cierre_inicio, this.filtro.fecha_cierre_fin]
                    : null
            },
            set(val){ 
                this.filtro.fecha_cierre_inicio = val !== null ? val[0] : null,
                this.filtro.fecha_cierre_fin = val !== null ? val[1] : null
            }
        },
        rangoProbabilidad:{
            get(){ return [this.filtro.probabilidad_minima, this.filtro.probabilidad_maxima] },
            set(val){ this.filtro.probabilidad_minima = val[0], this.filtro.probabilidad_maxima = val[1] },
        },
        rangoValor:{
            get(){ return [this.filtro.valor_minimo, this.filtro.valor_maximo] },
            set(val){ this.filtro.valor_minimo = val[0], this.filtro.valor_maximo = val[1] },
        }
    },
    watch: {
        async idCriteriosSelect(newVal, oldVal){
            let newCriterio = newVal.filter(elemento => oldVal.indexOf(elemento) == -1);
            if (newCriterio.length) {
                await this.addCriterio(newCriterio)
            }
            let deleteCriterio = {}
            if (oldVal.length) {
                deleteCriterio = oldVal.filter(elemento => newVal.indexOf(elemento) == -1);
            }
            if(deleteCriterio.length){
                this.quitarCriterio(deleteCriterio[0])
            }
        },
        dataCriteriosSelect(val){
            this.set_num_criterios_filtro(val.length ?? 0)
        }
    },
    async mounted(){
        this.cleanFilter()
    },
    methods: {
        listar(){
            this.$emit('listar');
        },
        ...mapActions({
            Action_get_criterio: 'oportunidades/oportunidades/Action_get_criterio',
            Action_clear_filtros: 'oportunidades/oportunidades/Action_clear_filtros',
            Action_delete_filtros: 'oportunidades/oportunidades/Action_delete_filtros',
            Action_get_select_countries: 'selects/selects/Action_get_select_countries',
            Action_save_criterio_general: 'oportunidades/oportunidades/Action_save_criterio_general',
            Action_save_criterio_ubicacion: 'oportunidades/oportunidades/Action_save_criterio_ubicacion',
            Action_save_criterio_segmentacion: 'oportunidades/oportunidades/Action_save_criterio_segmentacion',
            Action_get_selects_city_paginated: 'selects/selects/Action_get_selects_city_paginated',
            Action_get_selects_states_paginated: 'selects/selects/Action_get_selects_states_paginated',
        }),
        ...mapMutations({
            set_filtros: 'oportunidades/oportunidades/set_filtros',
            set_num_criterios_filtro: 'oportunidades/oportunidades/set_num_criterios_filtro',
        }),
        async toggle(){
            this.$refs.modalFiltrarOp.toggle()
        },
        async addCriterio(newCriterio){
            console.table(this.dataFiltros.lista_valores);
            let data = this.dataFiltros.lista_valores.find( f => f.id === newCriterio[0] )
            const modulo = this.getModulo(data.nombre)
            let submodulo = ''
            if (modulo == 'ubicaciones') {
                if (data.nombre == 'Estado') {
                    submodulo = 'estado'
                    await this.Action_get_selects_states_paginated()
                }else if (data.nombre == 'País'){
                    submodulo = 'pais'
                    await this.Action_get_select_countries()
                }else{
                    submodulo = 'ciudad'
                    await this.Action_get_selects_city_paginated()
                }
                this.dataCriteriosSelect.push({
                    id_criterio: data.id,
                    modulo: modulo,
                    id_segmentacion: null,
                    arrayFiltro: [],
                    id_cliente_dato: data.id_padre,
                    submodulo: submodulo,
                    superModulo: 8,
                    temporal: true,
                    nombre: data.nombre,
                })
            }else{
                await this.getDataCriterioSegmentacion( data.id, data.tipo )
                this.dataCriteriosSelect.push({
                    id_criterio: data.id,
                    id_segmentacion: null,
                    arrayFiltro: [],
                    arrayOld: [],
                    id_cliente_dato_seleccionado: data.id_x_dato_seleccionado,
                    modulo: modulo,
                    submodulo: null,
                    superModulo: 8,
                    temporal: true,
                    nombre: data.nombre,
                    data: this.criterioData,
                })
            }
        },
        async getDataCriterioSegmentacion( id_criterio, tipo ){
            await this.Action_get_criterio({ id_criterio, tipo})
        },
        async getDataCriterioPais(payload){
            await this.Action_get_select_countries(payload)
        },
        async getDataCriterioEstado(payload){
            this.loadingSelct = true
            await this.Action_get_selects_states_paginated({nombre: payload})
            this.loadingSelct = false
        },
        async getDataCriterioCiudad(payload){
            this.loadingSelct = true
            await this.Action_get_selects_city_paginated({nombre: payload})
            this.loadingSelct = false
        },
        getModulo(criterio){
            switch (criterio) {
                case 'Tipo Identificación':
                case 'Género':
                case 'Sector Productivo':
                    return 'lista'
                    break;
            
                case 'Número de Empleados':
                    return 'rangos'
                    break;
            
                case 'Tamaño':
                case 'Responsable':
                case 'Relación con ESSI':
                    return 'clasificacion'
                    break;
            
                case 'País':
                case 'Estado':
                case 'Ciudad':
                case 'Ciudad de Residencia':
                case 'Ciudad de Nacimiento':
                    return 'ubicaciones'
                    break;
            
                default:
                    return 'fabricacion'
                    break;
            }
        },
        quitarCriterio(deleteCriterio){
            this.dataCriteriosSelect = this.dataCriteriosSelect.filter( f => f.id_criterio !== deleteCriterio )
            this.idCriteriosSelect = this.idCriteriosSelect.filter( f => f !== deleteCriterio )
        },
        async setFiltros(){
            const promesas = []
            this.dataCriteriosSelect.forEach( async f => {
                if ( f.modulo == 'ubicaciones' ){
                    promesas.push(this.guardarCriterioUbicacion(f))
                }else{
                    f.arrayOld = f.data.map( e => e.id_padre )
                    promesas.push(this.guardarCriterioSegmentacion(f))
                }
            })
            await Promise.all(promesas)
            if (this.filtros.count() > 0) {
                this.filtroGeneral.fecha_cierre = this.rangoFechaCierre == null ? [] : this.rangoFechaCierre
                this.filtroGeneral.probabilidad = this.rangoProbabilidad
                this.filtroGeneral.valor =  [this.dataFiltros.valor_minimo, this.dataFiltros.valor_maximo ]  //this.rangoValor
                this.filtroGeneral.ciclos = this.filtro.ciclos
                this.filtroGeneral.tipo = this.filtro.tipos
                this.filtroGeneral.responsables = this.filtro.responsables
                await this.guardarFiltrosGenerales()
            }
        },
        async guardarFiltrosGenerales(){
            await this.Action_save_criterio_general(this.filtroGeneral)
        },
        async guardarCriterioUbicacion(criterio){
            await this.Action_save_criterio_ubicacion(criterio)
        },
        async guardarCriterioSegmentacion(criterio){
            await this.Action_save_criterio_segmentacion(criterio)
        },
        async cleanFilter(){
            this.Action_clear_filtros({
                rangeProb: [0, 100],
                rangeVal: [this.dataFiltros.valor_minimo, this.dataFiltros.valor_maximo]
            })
            this.idCriteriosSelect = []
            this.dataCriteriosSelect = []
            await this.Action_delete_filtros()
            this.listar()
        },
        async filtrarOp(){
            await this.Action_delete_filtros()
            await this.setFiltros()
            this.listar()
            this.$refs.modalFiltrarOp.toggle()
        },
        formatTooltipRange(val){
            return val + '%'
        },
        formatTooltipValor(val){
            return this.$options.filters.currency(val, '$', 2, { thousandsSeparator: '.'})
        }
    }
}
</script>

<style lang="scss" scoped>
.filtro-op{
    .bg-dg{
        background: #fff8ee;
    }
    .bg-criterio{
        background: #eef8ff;
    }
    .wh-box{
        width: 163px;
        height: 34px;
    }
    .wh-18{
        max-width: 18px;
        min-width: 18px;
        max-height: 18px;
        min-height: 18px;
        background: #5D5D5D;
    }
}
</style>
